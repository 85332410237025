/** @jsx jsx */
import { Box, Flex, Link, useColorMode, jsx } from "theme-ui"

const Footer = () => {
  const [colorMode, setColorMode] = useColorMode()
  const isDark = colorMode === `dark`
  const toggleColorMode = (e: any) => {
    setColorMode(isDark ? `light` : `dark`)
  }

  return (
    <Box as="footer" variant="footer">
      <div sx={{maxWidth:900}}>
        <a href="/promotional-games">Promotional Games | </a>
        <a href="/tablelimit">Table Limit Displays | </a>
        <a href="/connected-cannabis-retail">Connected Cannabis Retail | </a> 
        <a href="/reservations">Electronic Reservation Book | </a> 
        <a href="/data-collection-outreach-events">Data Collection Outreach Events | </a> 
        <a href="/consultant">Software Development & Consultancy | </a> 
        <a href="/classroom">Google Classroom Rewards</a> 

      </div>
      <br/>
      <button
        sx={{ variant: `buttons.toggle`, fontWeight: `semibold`, display: `block`, mx: `auto`, mb: 3 }}
        onClick={toggleColorMode}
        type="button"
        aria-label="Toggle dark mode"
      >
        {isDark ? `Light` : `Dark`}
         
      </button>
      Copyright &copy; {new Date().getFullYear()}. All rights reserved.
      <br />
      
    </Box>
  )
}

export default Footer