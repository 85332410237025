/** @jsx jsx */
import { jsx } from "theme-ui"
import Divider from "../elements/divider"
import Inner from "../elements/inner"
import Content from "../elements/content"
import SVG from "./svg"
import { UpDown, UpDownWide } from "../styles/animations"
// @ts-ignore
import Intro from "../sections/intro"
import Img from 'gatsby-image'
import {useStaticQuery, graphql} from "gatsby"



const Hero = ({ offset, factor = 1 }: { offset: number; factor?: number }) => {

const data = useStaticQuery(graphql`
query {
  ttmlogo: file(relativePath: {eq: "ttm-horiz.png"}) {
    childImageSharp {
      fluid(maxWidth:250){
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      
      }
    }
  }
}`
)  

  return (
  <div>
   
    <Img sx={{m:'0 auto',marginTop:'20px'}}
        fluid={data.ttmlogo.childImageSharp.fluid}
        alt="Touch This Media Logo"
    />   
       
    <Divider speed={0.2} offset={offset} factor={factor}>
      <UpDown>
        <SVG icon="coin" hiddenMobile width={48} stroke color="icon_orange" left="10%" top="20%" />
        <SVG icon="spades" width={48} stroke color="icon_red" left="60%" top="70%" />
        <SVG icon="coin" width={6} color="icon_darker" left="60%" top="15%" />
      </UpDown>
      <UpDownWide>
        <SVG icon="stats" hiddenMobile width={16} color="icon_blue" left="80%" top="10%" />
        <SVG icon="creditcard" width={12} stroke color="icon_brightest" left="90%" top="50%" />
        <SVG icon="coin" width={16} color="icon_darker" left="70%" top="90%" />
        <SVG icon="stats" width={16} stroke color="icon_darkest" left="30%" top="65%" />
        <SVG icon="cart" width={16} stroke color="icon_pink" left="28%" top="15%" />
        <SVG icon="coin" width={6} color="icon_darkest" left="75%" top="10%" />
        <SVG icon="trophy" hiddenMobile width={8} color="icon_darkest" left="45%" top="10%" />
      </UpDownWide>
      <SVG icon="cogs" hiddenMobile width={24} color="icon_darker" left="5%" top="70%" />
      <SVG icon="creditcard" width={6} color="icon_darkest" left="4%" top="20%" />
      <SVG icon="basket" width={12} color="icon_darkest" left="50%" top="60%" />
      <SVG icon="stats" width={8} color="icon_darkest" left="95%" top="90%" />
      <SVG icon="clubs" hiddenMobile width={24} color="icon_darker" left="40%" top="80%" />
      <SVG icon="creditcard" width={8} stroke color="icon_darker" left="25%" top="5%" />
      <SVG icon="cogs" width={64} color="icon_green" left="95%" top="5%" />
      <SVG icon="trophy" hiddenMobile width={64} color="icon_purple" left="5%" top="90%" />
      <SVG icon="coin" width={6} color="icon_darkest" left="10%" top="10%" />
      <SVG icon="trophy" width={12} color="icon_darkest" left="40%" top="30%" />
      <SVG icon="cogs" width={16} stroke color="icon_darker" left="10%" top="50%" />
      <SVG icon="basket" width={8} stroke color="icon_darker" left="80%" top="70%" />
    </Divider>
    <Content sx={{ variant: `texts.bigger` }} speed={0.4} offset={offset} factor={factor}>
      <Inner>
        <Intro />
      </Inner>
    </Content>
  </div>
  )
}

export default Hero