import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`About`}</h2>
    <p>{`Touch This Media (est. 2008) is a California-based software company with a broad expertise in the development of innovative products in a variety of market segments. `}</p>
    <p>{`The company philosophy centers on developing market solutions in partnership with market incumbents who have access to a large network of clients & active prospects. Through Touch This Media’s relationships with Casino Supplies & Services, CasinoTrac (TBTC), Tekvisions & Database Dynamics, we have deployed thousands of devices, hundreds of promotional games and served millions of clients at over 100 casino locations in North America. `}</p>
    <p>{`Creating and deploying promotional contests in concert with loyalty programs designed to attract & serve thousands of clients on a daily basis is the mainstay of Touch This Media.
Touch Touch Media offers year-round promotional games, including electronic scratch cards, match games, pick a winner sports and spin to win contests. All designed to reward clients for coming in & giving them an offer to come back.`}</p>
    <p>{`Interactive digital signage is a core competency of This This Media and our table limit display solution can be found at over 75 casinos in North America. Through our partnership with Casino Supplies & Services (sales and marketing) & Tekvisions (procures and supports hardware), we have successfully placed over 2500 units since the product's inception in 2017. `}</p>
    <p>{`Having pioneered interactive retail sales for Sony, Sephora and Dick’s Sporting Goods, we coupled our vast experience in customer acquisition & retention for casino loyalty programs to provide a connected shopping experience for cannabis retailers. Through the development of interfaces to store management systems, we provide a cohesive online sales & marketing solution designed to attract new clients and retain clients with increased regularity. `}</p>
    <p>{`Coupling our experience in gaming & loyalty, we have embarked upon a solution, through Aufheben Technologies, designed for primary and secondary schools aimed to facilitate increased student participation with the express purpose of increasing the national graduation rate. Teachers can now use advanSR in tandem with their Google Classroom to incentive students towards increased participation.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      