import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Services`}</h1>
    <ProjectCard title="Promotional Games & Contests" link="/promotional-games" bg="linear-gradient(to right, #2d2667 0%,#4e5dc7 100%)" mdxType="ProjectCard">
A continuous customer acquisition & retention solution for entertainment locations and retailers operating in highly competitive markets.
      <br />
      <br />
      <h6 style={{
        "color": "orange"
      }}>Read More</h6>
    </ProjectCard>
    <ProjectCard title="TABLE LIMIT DISPLAYS" link="/tablelimit" bg="linear-gradient(to right, #2d2667 0%,#4e5dc7 100%)" mdxType="ProjectCard">
A cost effective table limit display solution that showcases game rules, bet limits, odds & cross-promotional opportunities while meeting state gaming regulations.
      <br />
      <br />
      <h6 style={{
        "color": "orange"
      }}>Read More</h6>
    </ProjectCard>
    <ProjectCard title="Connected Cannabis Retail" link="/connected-cannabis-retail" bg="linear-gradient(to right, #2d2667 0%,#4e5dc7 100%)" mdxType="ProjectCard">
A continuous automated customer acquisition & fulfillment solution for cannabis retailers in search of a fully connected retail experience.
      <br />
      <br />
      <h6 style={{
        "color": "orange"
      }}>Read More</h6>
    </ProjectCard>
    <ProjectCard title="Electronic Reservation Book" link="/reservations" bg="linear-gradient(to right, #2d2667 0%,#4e5dc7 100%)" mdxType="ProjectCard">
A seamless booking solution that allows clients the ability to place reservations which can be easily managed by the restaurant staff, salon owner or any business with a focus on appointments.  
      <br />
      <br />
      <h6 style={{
        "color": "orange"
      }}>Read More</h6>
    </ProjectCard>
    <ProjectCard title="DATA COLLECTION OUTREACH EVENTS" link="/data-collection-outreach-events" bg="linear-gradient(to right, #2d2667 0%,#4e5dc7 100%)" mdxType="ProjectCard">
An event-based customer acquisition solution that serves up promotional contests which provide prospects with a chance to win prizes in exchange for their contact info.
      <br />
      <br />
      <h6 style={{
        "color": "orange"
      }}>Read More</h6>
    </ProjectCard>
    <ProjectCard title="SOFTWARE DEVELOPMENT AND CONSULTANCY" link="/consultant" bg="linear-gradient(to right, #2d2667 0%,#4e5dc7 100%)" mdxType="ProjectCard">
Thorough & thoughtful consultancy services associated with software productization,  market analysis, data analytics and development strategies for organizations. 
      <br />
      <br />
      <h6 style={{
        "color": "orange"
      }}>Read More</h6>
    </ProjectCard>
    <ProjectCard title="GOOGLE CLASSROOM REWARDS" link="/classroom" bg="linear-gradient(to right, #2d2667 0%,#4e5dc7 100%)" mdxType="ProjectCard">
A system to maintain, gamify & reward student engagement with an eye to increasing the national graduation rate.
      <br />
      <br />
      <h6 style={{
        "color": "orange"
      }}>Read More</h6>
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      