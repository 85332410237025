import React, { useState, useEffect }  from "react"
import { Parallax } from "react-spring/renderprops-addons.cjs"
import Layout from "../components/layout"
import Hero from "../components/hero"
import Projects from "../components/projects"
import About from "../components/about"
import Contact from "../components/contact"
import { window, exists } from "browser-monads"



const Cara = () => {
  // Client-side Runtime fetch browser width
  const { pages, projectsOffset, projectsFactor, aboutOffset, aboutFactor, contactOffset, contactFactor } = useWindowWidth(); // Our custom Hook
  
  return (
    <Layout>
      <Parallax pages={pages}>
        <Hero offset={0} factor={1} />
        <Projects offset={projectsOffset} factor={projectsFactor} />
        <About offset={aboutOffset} factor={aboutFactor} />
        <Contact offset={contactOffset} factor={contactFactor} />
      </Parallax>
    </Layout>
  )
}

function useWindowWidth() {
  const [width, setWidth] = useState(window.innerWidth);
  // default iphone 5 se
  let breakpoints = {
    pages: 10.8, 
    projectsOffset: 2.5, 
    projectsFactor: 2, 
    aboutOffset:6.8, 
    aboutFactor: 2.5,
    contactOffset:9, 
    contactFactor: 1.8,
    width: width
  };

  if(exists(window)){
    useEffect(() => {

      const handleResize = () => setWidth(window.innerWidth);
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    });
  
    switch (true) {


        // iphone 7 and X
        case (width >= 375 && width <= 410):
        breakpoints.pages = 8.8;
  
        breakpoints.projectsOffset = 1.8; 
        breakpoints.projectsFactor = 1; 

        breakpoints.aboutOffset = 5.8;
        breakpoints.aboutFactor = 1;
  
        breakpoints.contactOffset = 7.6;
        breakpoints.contactFactor = 1.2;
        break;
      
      // google pixel
      case (width >= 411 && width <= 413):
        breakpoints.pages = 10.2;
  
        breakpoints.projectsOffset = 2.7; 
        breakpoints.projectsFactor = 2; 

        breakpoints.aboutOffset = 6.3;
        breakpoints.aboutFactor = 2.5;
  
        breakpoints.contactOffset = 8.7;
        breakpoints.contactFactor = 1.5;
       
        break;

      case (width >= 414 && width <= 639):

        breakpoints.pages = 9.5;
  
        breakpoints.projectsOffset = 2; 
        breakpoints.projectsFactor = 4; 

        breakpoints.aboutOffset = 5.7;
        breakpoints.aboutFactor = 2.5;
  
        breakpoints.contactOffset = 8;
        breakpoints.contactFactor = 1.4;
        break;

     case (width >= 640 && width <= 767):
        breakpoints.pages = 6.4;
  
        breakpoints.projectsOffset = 2; 
        breakpoints.projectsFactor = 2; 

        breakpoints.aboutOffset = 4;
        breakpoints.aboutFactor = 2.5;
  
        breakpoints.contactOffset = 5.5;
       
        break;   

      case (width >= 768 && width <= 1250):
        breakpoints.pages = 6.4;
  
        breakpoints.projectsOffset = 1.52; 
        breakpoints.projectsFactor = 2; 

        breakpoints.aboutOffset = 3.8;
        breakpoints.aboutFactor = 2.5;
  
        breakpoints.contactOffset = 5;
        breakpoints.contactFactor = 1.4;
       
        break;
      case (width >= 1280):
          breakpoints.pages = 6.6;
    
          breakpoints.projectsOffset = 1.52; 
          breakpoints.projectsFactor = 2; 
  
          breakpoints.aboutOffset = 3.8;
          breakpoints.aboutFactor = 2.5;
    
          breakpoints.contactOffset = 5;
          breakpoints.contactFactor = 1.4;
         
          break;  

    }
  }
  
 

  return breakpoints;
}



export default Cara